import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';

// Import images and videos for all projects
import img1 from '../Assets/Projects/P1/ag1.jpg';
import img2 from '../Assets/Projects/P1/ag2.jpg';
import img3 from '../Assets/Projects/P1/ag3.jpg';
import img4 from '../Assets/Projects/P1/ag4.jpg';
import img5 from '../Assets/Projects/P1/ag5.jpg';
import img6 from '../Assets/Projects/P1/ag6.jpg';
import img7 from '../Assets/Projects/P1/ag7.jpg';
import img8 from '../Assets/Projects/P1/ag8.jpg';
import img9 from '../Assets/Projects/P1/ag9.jpg';
import img10 from '../Assets/Projects/P1/ag10.jpg';
import img11 from '../Assets/Projects/P1/ag11.jpg';
import img12 from '../Assets/Projects/P1/ag12.jpg';
import img13 from '../Assets/Projects/P1/ag13.jpg';
import video1 from '../Assets/Videos/Drone_Video.mp4';

import img21 from '../Assets/Projects/P2/av1.jpeg';
import img22 from '../Assets/Projects/P2/av2.jpeg';
import img23 from '../Assets/Projects/P2/av3.jpeg';
import img24 from '../Assets/Projects/P2/av4.jpeg';
import img25 from '../Assets/Projects/P2/av5.jpeg';
import img26 from '../Assets/Projects/P2/av6.jpeg';
import img27 from '../Assets/Projects/P2/av7.jpeg';
import img28 from '../Assets/Projects/P2/av8.jpeg';
import img29 from '../Assets/Projects/P2/av9.jpeg';
import img210 from '../Assets/Projects/P2/av10.jpeg';
import img211 from '../Assets/Projects/P2/av11.jpg';
import img212 from '../Assets/Projects/P2/av12.jpg';
import img213 from '../Assets/Projects/P2/av13.jpg';
import img214 from '../Assets/Projects/P2/av14.jpg';

import img31 from '../Assets/Projects/P3/ch1.jpg';
import img32 from '../Assets/Projects/P3/ch2.jpg';
import img33 from '../Assets/Projects/P3/ch3.jpg';
import img34 from '../Assets/Projects/P3/ch4.jpg';
import img35 from '../Assets/Projects/P3/ch5.jpg';
import img36 from '../Assets/Projects/P3/ch6.jpg';
import img37 from '../Assets/Projects/P3/ch7.jpg';
import img38 from '../Assets/Projects/P3/ch8.jpg';
import img39 from '../Assets/Projects/P3/ch9.jpg';
import img310 from '../Assets/Projects/P3/ch10.jpg';
import img311 from '../Assets/Projects/P3/ch11.jpg';
import img312 from '../Assets/Projects/P3/ch12.jpg';
import img313 from '../Assets/Projects/P3/ch13.jpg';
import img314 from '../Assets/Projects/P3/ch14.jpg';
import video from '../Assets/Projects/P3/video.mp4';

import img41 from '../Assets/Projects/P4/ch1.jpg';
import img42 from '../Assets/Projects/P4/ch2.jpg';
import img43 from '../Assets/Projects/P4/ch3.jpg';
import img44 from '../Assets/Projects/P4/ch4.jpg';
import img45 from '../Assets/Projects/P4/ch5.jpg';
import img46 from '../Assets/Projects/P4/ch6.jpg';
import img47 from '../Assets/Projects/P4/ch7.JPG';
import img48 from '../Assets/Projects/P4/ch8.JPG';
import img49 from '../Assets/Projects/P4/ch9.jpg';
import img410 from '../Assets/Projects/P4/ch10.JPG';
import img411 from '../Assets/Projects/P4/ch11.JPG'
import img412 from '../Assets/Projects/P4/ch12.jpg';
import img413 from '../Assets/Projects/P4/ch13.JPG';
import img414 from '../Assets/Projects/P4/ch14.JPG';
import img415 from '../Assets/Projects/P4/ch15.JPG';
import img416 from '../Assets/Projects/P4/ch16.JPG';
import img417 from '../Assets/Projects/P4/ch17.JPG';
import img418 from '../Assets/Projects/P4/ch18.JPG';
import img419 from '../Assets/Projects/P4/ch19.jpg';
import img420 from '../Assets/Projects/P4/ch20.JPG';

import img51 from '../Assets/Projects/P5/ko1.jpg';
import img52 from '../Assets/Projects/P5/ko2.JPG';
import img53 from '../Assets/Projects/P5/ko3.jpg';
import img54 from '../Assets/Projects/P5/ko4.jpg';
import img55 from '../Assets/Projects/P5/ko5.jpg';
import img56 from '../Assets/Projects/P5/ko6.JPG';
import img57 from '../Assets/Projects/P5/ko7.JPG';
import img58 from '../Assets/Projects/P5/ko8.JPG';
import img59 from '../Assets/Projects/P5/ko9.JPG';
import img510 from '../Assets/Projects/P5/ko10.JPG';
import img511 from '../Assets/Projects/P5/ko11.JPG'
import img512 from '../Assets/Projects/P5/ko12.JPG';
import img513 from '../Assets/Projects/P5/ko13.JPG';
import img514 from '../Assets/Projects/P5/ko14.jpg';
import img515 from '../Assets/Projects/P5/ko15.JPG';
import img516 from '../Assets/Projects/P5/ko16.JPG';
import img517 from '../Assets/Projects/P5/ko17.jpg';
import img518 from '../Assets/Projects/P5/ko18.jpg';
import img519 from '../Assets/Projects/P5/ko19.JPG';
import img520 from '../Assets/Projects/P5/ko20.JPG';

const ProjectGallery = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const [activeMediaIndex, setActiveMediaIndex] = useState(null); // Track which media is open in the modal

  // Create a mapping of media for each project
  const projectMedia = {
    "BNGAGRBN01-gallery": [
      { type: 'image', src: img1 },
      { type: 'image', src: img2 },
      { type: 'image', src: img3 },
      { type: 'image', src: img4 },
      { type: 'image', src: img5 },
      { type: 'image', src: img6 },
      { type: 'image', src: img7 },
      { type: 'image', src: img8 },
      { type: 'image', src: img9 },
      { type: 'image', src: img10 },
      { type: 'image', src: img11 },
      { type: 'image', src: img12 },
      { type: 'image', src: img13 },
      { type: 'video', src: video1 },
    ],
    "MYSAVGBN03-gallery": [
      { type: 'image', src: img21 },
      { type: 'image', src: img22 },
      { type: 'image', src: img23 },
      { type: 'image', src: img24 },
      { type: 'image', src: img25 },
      { type: 'image', src: img26 },
      { type: 'image', src: img27 },
      { type: 'image', src: img28 },
      { type: 'image', src: img29 },
      { type: 'image', src: img210 },
      { type: 'image', src: img211 },
      { type: 'image', src: img212 },
      { type: 'image', src: img213 },
      { type: 'image', src: img214 },
    ],
    "MYSCHMBN02-gallery": [
      { type: 'image', src: img31 },
      { type: 'image', src: img32 },
      { type: 'image', src: img33 },
      { type: 'image', src: img34 },
      { type: 'image', src: img35 },
      { type: 'image', src: img36 },
      { type: 'image', src: img37 },
      { type: 'image', src: img38 },
      { type: 'image', src: img39 },
      { type: 'image', src: img310 },
      { type: 'image', src: img311 },
      { type: 'image', src: img312 },
      { type: 'image', src: img313 },
      { type: 'image', src: img314 },
      { type: 'video', src: video },
    ],
    "MYSCHMBN04-gallery": [
      { type: 'image', src: img41 },
      { type: 'image', src: img42 },
      { type: 'image', src: img43 },
      { type: 'image', src: img44 },
      { type: 'image', src: img45 },
      { type: 'image', src: img46 },
      { type: 'image', src: img47 },
      { type: 'image', src: img48 },
      { type: 'image', src: img49 },
      { type: 'image', src: img410 },
      { type: 'image', src: img411 },
      { type: 'image', src: img412 },
      { type: 'image', src: img413 },
      { type: 'image', src: img414 },
      { type: 'image', src: img415 },
      { type: 'image', src: img416 },
      { type: 'image', src: img417 },
      { type: 'image', src: img418 },
      { type: 'image', src: img419 },
      { type: 'image', src: img420 },
    ],
    "MYSKTGBN05-gallery": [
      { type: 'image', src: img51 },
      { type: 'image', src: img52 },
      { type: 'image', src: img53 },
      { type: 'image', src: img54 },
      { type: 'image', src: img55 },
      { type: 'image', src: img56 },
      { type: 'image', src: img57 },
      { type: 'image', src: img58 },
      { type: 'image', src: img59 },
      { type: 'image', src: img510 },
      { type: 'image', src: img511 },
      { type: 'image', src: img512 },
      { type: 'image', src: img513 },
      { type: 'image', src: img514 },
      { type: 'image', src: img515 },
      { type: 'image', src: img516 },
      { type: 'image', src: img517 },
      { type: 'image', src: img518 },
      { type: 'image', src: img519 },
      { type: 'image', src: img520 },
    ],
    // Add more projects as needed
  };

  // Get the media for the current projectId
  const galleryMedia = projectMedia[projectId] || [];

  // Handle opening media in the modal
  const openMedia = (index) => {
    setActiveMediaIndex(index);
  };

  // Handle closing the modal
  const closeModal = () => {
    setActiveMediaIndex(null);
  };

  // Handle navigating through media in the modal
  const nextMedia = () => {
    setActiveMediaIndex((prev) => (prev + 1) % galleryMedia.length);
  };

  const prevMedia = () => {
    setActiveMediaIndex((prev) => (prev - 1 + galleryMedia.length) % galleryMedia.length);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-green-100 to-green-300 p-4 md:p-8">
      <div className="max-w-7xl mx-auto">
        <button
          onClick={() => navigate('/')}
          className="  mb-6 flex items-center gap-2 text-green-800 hover:text-green-600 transition-colors duration-300"
        >
          <ChevronLeft className=" w-5 h-5" />
          <span>Back to Projects</span>
        </button>

        <h1 className="text-3xl md:text-4xl font-bold text-green-900 mb-8 capitalize">
          {projectId.replace("-", " ")} 
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {galleryMedia.map((media, index) => (
            <div
              key={index}
              className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer"
              onClick={() => openMedia(index)}
            >
              {media.type === 'image' ? (
                <img
                  src={media.src}
                  alt={`Gallery image ${index + 1}`}
                  className="w-full h-52 md:h-64 object-cover"
                />
              ) : (
                <video
                  controls
                  className="w-full h-48 md:h-64 object-cover"
                >
                  <source src={media.src} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}
            </div>
          ))}
        </div>

        {/* Modal for viewing media */}
        {activeMediaIndex !== null && (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
    <div className="relative w-full max-w-6xl h-auto max-h-[90vh] flex items-center justify-center p-4">
      
      {/* Close Button */}
      <button
        onClick={closeModal}
        aria-label="Close Modal"
        className="absolute top-4 right-4 text-white bg-black bg-opacity-50 rounded-full p-1 hover:bg-opacity-70 z-50"
      >
        <X className="w-6 h-6 sm:w-8 sm:h-8" />
      </button>

      {/* Media Content */}
      <div className="flex items-center justify-center w-full h-full">
        {galleryMedia[activeMediaIndex].type === 'image' ? (
          <img
            src={galleryMedia[activeMediaIndex].src}
            alt={`Modal image ${activeMediaIndex + 1}`}
            className="w-full h-full max-h-[80vh] max-w-[95vw] object-contain rounded-md"
          />
        ) : (
          <video controls className="w-full h-full max-h-[95vh] max-w-[95vw] object-contain rounded-md">
            <source src={galleryMedia[activeMediaIndex].src} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>

      {/* Navigation Buttons */}
      <button
        onClick={prevMedia}
        aria-label="Previous Media"
        className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white bg-black bg-opacity-60 p-2 rounded-full hover:bg-opacity-80 z-50"
      >
        <ChevronLeft className="w-5 h-5 sm:w-8 sm:h-8" />
      </button>
      <button
        onClick={nextMedia}
        aria-label="Next Media"
        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white bg-black bg-opacity-60 p-2 rounded-full hover:bg-opacity-80 z-50"
      >
        <ChevronRight className="w-5 h-5 sm:w-8 sm:h-8" />
      </button>
    </div>
  </div>
)}


      </div>
    </div>
  );
};

export default ProjectGallery;
