import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useSwipeable } from 'react-swipeable';
import solutions1 from '../Assets/Images/solution1.jpg';
import solutions2 from '../Assets/Images/solution2.jpg';
import solutions3 from '../Assets/Images/solution3.jpeg';
import solutions4 from '../Assets/Images/solution4.jpg';
import solutions5 from '../Assets/Images/solution5.jpg';

const solutions = [
  {
    title: 'AI Disease Management',
    description: 'Our advanced AI algorithms detect early signs of crop diseases, allowing for timely intervention. This proactive approach significantly reduces crop loss and increases overall yield.',
    icon: '🌿',
    image: solutions1,
    color: '#4CAF50',
    benefit: 'Increase yield by up to 30%',
    key: 'Real-time disease detection through machine learning algorithms.',
    launchingSoon: true,
  },
  {
    title: 'IoT Sensors',
    description: 'Our state-of-the-art IoT sensors provide real-time data on soil moisture, temperature, and environmental conditions. This constant stream of information enables precise decision-making and resource allocation.',
    icon: '📡',
    image: solutions2,
    color: '#2196F3',
    benefit: 'Reduce resource waste by 25%',
    key: 'Precision environmental monitoring with low-latency data transmission.',
  },
  {
    title: 'Smart Irrigation System',
    description: 'Our AI-driven irrigation system optimizes water usage based on real-time weather data, soil conditions, and crop requirements. This ensures your crops get exactly the right amount of water, when they need it.',
    icon: '💧',
    image: solutions3,
    color: '#00BCD4',
    benefit: 'Save up to 40% on water consumption',
    key: 'Dynamic irrigation scheduling using AI models.',
  },
  {
    title: 'Integrated Farm Security',
    description: 'Our comprehensive security solution combines AI-powered cameras, motion sensors, and automated alerts to protect your farm from theft, vandalism, and unauthorized access.',
    icon: '🔒',
    image: solutions4,
    color: '#F44336',
    benefit: 'Reduce theft and damages by 50%',
    key: 'Automated alerts for unauthorized access or equipment tampering.',
  },
  {
    title: 'Expert Agri Consultancy',
    description: 'Our team of experienced agronomists provides personalized guidance on crop selection, rotation strategies, and sustainable farming practices. We help you optimize your farm`s performance while ensuring long-term sustainability.',
    icon: '👨‍🌾',
    image: solutions5,
    color: '#FFC107',
    benefit: 'Boost overall farm efficiency by 35%',
    key: 'Advanced analytics for yield prediction and optimization strategies.',
  }
];

const SolutionCard = ({ solution, isActive, onClick, screenSize }) => (
  <motion.div
    className={`cursor-pointer p-4 rounded-lg ${
      isActive ? 'bg-white shadow-lg' : 'bg-gray-100'
    } transition-all duration-300 relative`}
    whileHover={{ scale: screenSize === 'mobile' ? 1.03 : 1.06 }}
    onClick={onClick}
    style={{
      width: '100%',
      maxWidth: screenSize === 'mobile' ? '100%' : isActive ? '100%' : '90%',
    }}
  >
    <div className="flex items-center gap-3">
      <span className="text-2xl sm:text-3xl">{solution.icon}</span>
      <div className="flex-1 min-w-0">
        <h3 
          className={`text-base sm:text-lg font-semibold truncate ${
            isActive ? '' : 'text-gray-600'
          }`}
          style={{ color: isActive ? solution.color : undefined }}
        >
          {solution.title}
        </h3>
        <p className="text-xs text-gray-500 truncate">{solution.benefit}</p>
      </div>
    </div>
    {screenSize !== 'mobile' && isActive && (
      <motion.p 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        className="mt-2 text-sm text-gray-600 line-clamp-2"
      >
        {solution.description}
      </motion.p>
    )}
  </motion.div>
);

const AgriTechSolutionsExplorer = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [screenSize, setScreenSize] = useState('desktop');
  const [isHovering, setIsHovering] = useState(false);
  const autoScrollIntervalRef = useRef(null);
  const lastSwipeTime = useRef(Date.now());

  // Handle screen size changes
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setScreenSize(width < 640 ? 'mobile' : width < 1024 ? 'tablet' : 'desktop');
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Auto-slide interval control
  useEffect(() => {
    const startAutoSlide = () => {
      autoScrollIntervalRef.current = setInterval(() => {
        if (Date.now() - lastSwipeTime.current > 1000) {
          setActiveIndex((prevIndex) => (prevIndex + 1) % solutions.length);
        }
      }, 4000);
    };

    if (!isHovering) startAutoSlide();

    return () => clearInterval(autoScrollIntervalRef.current);
  }, [isHovering]);

  // Swipe handlers for mobile
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setActiveIndex((prev) => (prev + 1) % solutions.length);
      lastSwipeTime.current = Date.now();
    },
    onSwipedRight: () => {
      setActiveIndex((prev) => (prev - 1 + solutions.length) % solutions.length);
      lastSwipeTime.current = Date.now();
    },
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  });

  return (
    <div className="min-h-screen bg-gradient-to-tr from-green-100 to-green-300 p-4 sm:p-6 lg:p-8" {...handlers}>
      <div className="max-w-7xl mx-auto">
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-6 sm:mb-10 lg:mb-14 text-center text-[rgb(2,58,16)]" id="solutions">
          Transforming Agriculture Practices with Cutting-Edge Technology
        </h1>

        <div className="flex flex-col lg:flex-row gap-6 lg:gap-8">
          {screenSize !== 'mobile' ? (
            <div className="lg:w-1/3 xl:w-1/4">
              <div className="flex lg:flex-col gap-3 overflow-x-auto lg:overflow-x-visible pb-4 lg:pb-0 snap-x lg:snap-none">
                {solutions.map((solution, index) => (
                  <div key={index} className="snap-start lg:snap-none min-w-[200px] sm:min-w-[250px] lg:min-w-0">
                    <SolutionCard
                      solution={solution}
                      isActive={index === activeIndex}
                      onClick={() => setActiveIndex(index)}
                      screenSize={screenSize}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex justify-center gap-2 mb-4">
              {solutions.map((_, index) => (
                <div
                  key={index}
                  className={`w-2 h-2 rounded-full ${index === activeIndex ? 'bg-green-600' : 'bg-gray-300'}`}
                />
              ))}
            </div>
          )}

          <div
            className="lg:w-2/3 xl:w-3/4"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <AnimatePresence mode="wait">
              <motion.div
                key={activeIndex}
                initial={{ opacity: 0, x: screenSize === 'mobile' ? 15 : 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: screenSize === 'mobile' ? -15 : -20 }}
                transition={{
                  type: 'spring',
                  stiffness: 120,
                  damping: 20,
                  duration: 0.5,
                  ease: 'easeInOut',
                }}
                className="bg-white rounded-xl shadow-xl overflow-hidden transition-colors duration-300 hover:bg-gray-800 hover:text-white group relative"
              >
                {solutions[activeIndex].launchingSoon && (
                  <div className="absolute top-4 right-4 z-10">
                    <div className="bg-red-500 text-white px-4 py-2 rounded-lg shadow-lg font-semibold animate-pulse">
                      Launching Soon
                    </div>
                  </div>
                )}

                <div className={`${screenSize === 'mobile' ? 'flex flex-col' : 'grid grid-cols-2'}`}>
                  <div className="relative">
                    <motion.img
                      src={solutions[activeIndex].image}
                      alt={solutions[activeIndex].title}
                      className="w-full h-full object-cover"
                      style={{ maxHeight: screenSize === 'mobile' ? '250px' : '500px' }}
                      initial={{ opacity: 0.8 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 1 }}
                    />
                    <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/70 to-transparent p-4">
                      <div className="text-white text-sm font-semibold">
                        Key Benefit:
                        <span className="ml-2 text-gray-300">{solutions[activeIndex].key}</span>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col p-6 lg:p-8">
                    <div className="flex items-center gap-4 mb-6">
                      <div className="bg-gray-100 p-3 rounded-full">
                        <span className="text-4xl">{solutions[activeIndex].icon}</span>
                      </div>
                      <h2
                        className="text-2xl lg:text-3xl font-bold transition-colors duration-300 group-hover:text-white"
                        style={{ color: solutions[activeIndex].color }}
                      >
                        {solutions[activeIndex].title}
                      </h2>
                    </div>

                    <div className="flex-1">
                      <p className="text-base lg:text-lg leading-relaxed font-serif transition-colors duration-300 group-hover:text-gray-200 mb-6">
                        {solutions[activeIndex].description}
                      </p>
                      <div className="h-px bg-gray-200 group-hover:bg-gray-700 transition-colors duration-300" />
                    </div>

                    <div className="mt-6">
                      <div className="bg-gradient-to-r from-green-500 to-blue-500 text-white p-4 rounded-lg">
                        <div className="flex items-center justify-between">
                          <span className="text-sm font-medium">Expected Impact:</span>
                          <span className="text-lg font-bold">{solutions[activeIndex].benefit}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgriTechSolutionsExplorer;



