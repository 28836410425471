// import React, { useState } from 'react';
// import { motion } from 'framer-motion';
// import { Leaf, Mail, Phone, MapPin, Send, User } from 'lucide-react';
// import axios from 'axios';

// const EcoInteractiveContact = () => {
//   const [formData, setFormData] = useState({
//     name: '', email: '', phone: '', message: ''
//   });
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [isSubmitted, setIsSubmitted] = useState(false);

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsSubmitting(true);
//     try {
//       await axios.post('http://localhost:5000/send-mail', formData);
//       setIsSubmitted(true);
//     } catch (error) {
//       console.error('Error sending message:', error);
//       alert('Failed to send message');
//     } finally {
//       setIsSubmitting(false);
//     }
//   };

//   const renderForm = () => (
//     <motion.form
//       initial={{ opacity: 0, y: 50 }}
//       animate={{ opacity: 1, y: 0 }}
//       transition={{ duration: 0.5 }}
//       onSubmit={handleSubmit}
//       className="space-y-4"
//     >
//       <h2 className="text-3xl font-bold text-green-700 mb-6">Get in Touch</h2>
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
//         <div className="relative">
//           <input
//             type="text"
//             name="name"
//             value={formData.name}
//             onChange={handleChange}
//             placeholder="Your Name"
//             className="w-full p-3 border-b-2 border-green-500 focus:outline-none focus:border-green-700 bg-transparent"
//           />
//           <User className="absolute right-3 top-3 text-green-500" size={20} />
//         </div>
//         <div className="relative">
//           <input
//             type="email"
//             name="email"
//             value={formData.email}
//             onChange={handleChange}
//             placeholder="Your Email"
//             className="w-full p-3 border-b-2 border-green-500 focus:outline-none focus:border-green-700 bg-transparent"
//           />
//           <Mail className="absolute right-3 top-3 text-green-500" size={20} />
//         </div>
//       </div>
//       <div className="relative">
//         <input
//           type="tel"
//           name="phone"
//           value={formData.phone}
//           onChange={handleChange}
//           placeholder="Your Phone"
//           className="w-full p-3 border-b-2 border-green-500 focus:outline-none focus:border-green-700 bg-transparent"
//         />
//         <Phone className="absolute right-3 top-3 text-green-500" size={20} />
//       </div>
//       <div className="relative">
//         <textarea
//           name="message"
//           value={formData.message}
//           onChange={handleChange}
//           placeholder="Your Message"
//           className="w-full p-3 border-b-2 border-green-500 focus:outline-none focus:border-green-700 bg-transparent resize-none"
//           rows="4"
//         ></textarea>
//         <Send className="absolute right-3 top-3 text-green-500" size={20} />
//       </div>
//       <button
//         type="submit"
//         disabled={isSubmitting}
//         className="w-full bg-green-600 text-white py-3 rounded-full font-semibold hover:bg-green-700 transition duration-300 flex items-center justify-center"
//       >
//         {isSubmitting ? 'Sending...' : 'Send Message'}
//         <Send className="ml-2" size={20} />
//       </button>
//     </motion.form>
//   );

//   const renderThankYou = () => (
//     <motion.div
//       initial={{ opacity: 0, scale: 0.8 }}
//       animate={{ opacity: 1, scale: 1 }}
//       transition={{ duration: 0.5 }}
//       className="flex flex-col items-center justify-center h-full text-center"
//     >
//       <Leaf className="text-green-500 mb-4" size={48} />
//       <h2 className="text-3xl font-bold text-green-700 mb-2">Thank You!</h2>
//       <p className="text-gray-600 font-serif">We've received your message and will get back to you soon.</p>
//     </motion.div>
//   );

//   const contactInfo = [
//     { icon: Phone, text: '+918618944125', href: 'tel:+918618944125' },
//     { icon: Mail, text: 'connect@kiaanagrow.com', href: 'mailto:connect@kiaanagrow.com' },
//     { icon: MapPin, text: 'Rajarajeshwari Nagar, Bengaluru – 560098', href: '#map' },
//   ];

//   return (
//     <div className="min-h-screen bg-gradient-to-tr from-green-100 to-green-300 py-12 px-4" id="contact">
//       <div className="max-w-6xl mx-auto bg-white/80 backdrop-blur-md rounded-xl shadow-2xl overflow-hidden">
//         <div className="flex flex-col lg:flex-row">
//           <div className="w-full lg:w-3/5 p-8 flex items-center justify-center">
//             {isSubmitted ? renderThankYou() : renderForm()}
//           </div>
//           <div className="w-full lg:w-2/5 bg-green-600 p-8 flex items-center justify-center">
//             <motion.div
//               initial={{ opacity: 0, y: 50 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ duration: 0.5, delay: 0.2 }}
//               className="text-center text-white"
//             >
//               <h1 className="text-4xl font-bold mb-4">Grow With Us</h1>
//               <p className="mb-8 font-serif">Join our mission for sustainable agriculture</p>
//               <motion.div
//                 className="w-40 h-40 mx-auto bg-white rounded-full flex items-center justify-center"
//                 animate={{ rotate: 360 }}
//                 transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
//               >
//                 <Leaf className="text-green-600" size={64} />
//               </motion.div>
//               <div className="mt-12 space-y-6">
//                 {contactInfo.map(({ icon: Icon, text, href }, index) => (
//                   <motion.a
//                     key={index}
//                     href={href}
//                     className="flex items-center space-x-3 p-4 bg-green-500 rounded-lg hover:bg-green-400 transition duration-300"
//                     whileHover={{ scale: 1.05 }}
//                     whileTap={{ scale: 0.95 }}
//                   >
//                     <Icon className="text-white" size={26} />
//                     <span className="text-white font-medium">{text}</span>
//                   </motion.a>
//                 ))}
//               </div>
//             </motion.div>
//           </div>
//         </div>
//       </div>
//       <div id="map" className="mt-12 max-w-6xl mx-auto bg-white/80 backdrop-blur-md rounded-xl shadow-2xl overflow-hidden">
//         <h2 className="text-3xl font-bold text-green-700 p-6">Our Location</h2>
//         <div className="h-96 w-full">
//           <iframe
//             title="Kiaan AgrowTech Location"
//             src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.923068481783!2d77.52400447484034!3d12.912665987397288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3f02cb8e1313%3A0xf7f08010f477ecc4!2sKiaan%20AgrowTech!5e0!3m2!1sen!2sin!4v1724654386321!5m2!1sen!2sin"
//             className="w-full h-full border-0"
//             allowFullScreen
//             loading="lazy"
//             referrerPolicy="no-referrer-when-downgrade"
//           ></iframe>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EcoInteractiveContact;

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Leaf, Mail, Phone, MapPin, Send, User } from 'lucide-react';
import axios from 'axios';

const EcoInteractiveContact = () => {
  const [formData, setFormData] = useState({
    name: '', email: '', phone: '', message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await axios.post('https://kiaanagrow.com/api/send-mail', formData);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderForm = () => (
    <motion.form
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      onSubmit={handleSubmit}
      className="space-y-4"
    >
      <h2 className="text-3xl font-bold text-green-700 mb-6">Get in Touch</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="relative">
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Your Name"
            className="w-full p-3 border-b-2 border-green-500 focus:outline-none focus:border-green-700 bg-transparent"
          />
          <User className="absolute right-3 top-3 text-green-500" size={20} />
        </div>
        <div className="relative">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Your Email"
            className="w-full p-3 border-b-2 border-green-500 focus:outline-none focus:border-green-700 bg-transparent"
          />
          <Mail className="absolute right-3 top-3 text-green-500" size={20} />
        </div>
      </div>
      <div className="relative">
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Your Phone"
          className="w-full p-3 border-b-2 border-green-500 focus:outline-none focus:border-green-700 bg-transparent"
        />
        <Phone className="absolute right-3 top-3 text-green-500" size={20} />
      </div>
      <div className="relative">
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Your Message"
          className="w-full p-3 border-b-2 border-green-500 focus:outline-none focus:border-green-700 bg-transparent resize-none"
          rows="4"
        ></textarea>
        <Send className="absolute right-3 top-3 text-green-500" size={20} />
      </div>
      <button
        type="submit"
        disabled={isSubmitting}
        className="w-full bg-green-600 text-white py-3 rounded-full font-semibold hover:bg-green-700 transition duration-300 flex items-center justify-center"
      >
        {isSubmitting ? 'Sending...' : 'Send Message'}
        <Send className="ml-2" size={20} />
      </button>
    </motion.form>
  );

  const renderThankYou = () => (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="flex flex-col items-center justify-center h-full text-center"
    >
      <Leaf className="text-green-500 mb-4" size={48} />
      <h2 className="text-3xl font-bold text-green-700 mb-2">Thank You!</h2>
      <p className="text-gray-600 font-serif">We've received your message and will get back to you soon.</p>
    </motion.div>
  );

  const contactInfo = [
    { icon: Phone, text: '+918618944125', href: 'tel:+918618944125' },
    { icon: Mail, text: 'connect@kiaanagrow.com', href: 'mailto:connect@kiaanagrow.com' },
    { icon: MapPin, text: 'Rajarajeshwari Nagar, Bengaluru – 560098', href: '#map' },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-tr from-green-100 to-green-300 py-12 px-4" id="contact">
    <div className="max-w-6xl mx-auto bg-white/80 backdrop-blur-md rounded-xl shadow-2xl overflow-hidden">
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 p-8 flex items-center justify-center">
          {isSubmitted ? renderThankYou() : renderForm()}
        </div>
        <div className="w-full lg:w-1/2 bg-green-600 p-8 flex flex-col items-center justify-center">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-center text-white"
          >
            <h1 className="text-4xl font-bold mb-4">Grow With Us</h1>
            <p className="mb-8 font-serif">Join our mission for sustainable agriculture</p>
            <div className="mt-12 space-y-6">
              {contactInfo.map(({ icon: Icon, text, href }, index) => (
                <motion.a
                  key={index}
                  href={href}
                  className="flex items-center space-x-3 p-4 bg-green-500 rounded-lg hover:bg-green-400 transition duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Icon className="text-white" size={26} />
                  <span className="text-white font-medium">{text}</span>
                </motion.a>
              ))}
            </div>
          </motion.div>
          <div className="mt-8 w-full">
            <div className="h-64 w-full rounded-lg overflow-hidden">
              <iframe
                title="Kiaan AgrowTech Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.923068481783!2d77.52400447484034!3d12.912665987397288!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3f02cb8e1313%3A0xf7f08010f477ecc4!2sKiaan%20AgrowTech!5e0!3m2!1sen!2sin!4v1724654386321!5m2!1sen!2sin"
                className="w-full h-full border-0"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
  );
};

export default EcoInteractiveContact;