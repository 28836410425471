import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './Home/Navbar';
import Hero from './Home/Hero';
import Card from './Home/Solutions';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Projects from './Home/Projects';
import Footer from './Home/Footer';
import FarmManagementSoftwarePromo from './Home/Ecom';
import ContactSection from './Home/Contact';
import ChangeMakersSection from './Home/ChangeMakers';
import Feature from './Home/Feature';
import Mission from './Home/Mission';
import Partners from './Home/Partners';
import Story from './Home/Story';
import ScrollToTopButton from './Home/ScrollToTop';
import Preloader from './Home/PreLoader';
import EcomSoftwarePromo from './Home/Farmsoft';
import ProjectGallery from './Home/Gallery';

const App = () => {
  const location = useLocation();

  // List of paths where the navbar should be hidden
  const hideNavbarPaths = ['/gallery/:projectId'];

  // Check if the current path matches any of the paths where the navbar should be hidden
  const shouldHideNavbar = hideNavbarPaths.some((path) => location.pathname.startsWith('/gallery'));

  return (
    <>
      <Preloader />
      
      {/* Conditionally render the Navbar */}
      {!shouldHideNavbar && <Navbar />}

      <Routes>
        <Route path="/" element={
          <>
            <Hero />
            <Feature />
            <Story />
            <Card />
            <Mission />
            <EcomSoftwarePromo />
            <FarmManagementSoftwarePromo />
            <Projects />
            <Partners />
            <ChangeMakersSection />
            <ContactSection />
            <Footer />
            <ScrollToTopButton />
          </>
        } />
        
        {/* Define routes for the Projects and Gallery pages */}
        <Route path="/projects" element={<Projects />} />
        <Route path="/gallery/:projectId" element={<ProjectGallery />} />
      </Routes>
    </>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
