import React from 'react';
import PropTypes from 'prop-types';

const KiaanButton = ({ children, href }) => {
  const handleClick = (e) => {
    e.preventDefault(); // Prevent default anchor behavior

    const targetElement = document.querySelector(href);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  if (href) {
    return (
      <a 
        href={href} 
        onClick={handleClick} 
        className="relative inline-block align-middle appearance-none border-none outline-none bg-transparent rounded-none text-green-400 text-base font-semibold font-[Rubik,sans-serif] py-3.5 px-10 uppercase overflow-hidden text-center transition duration-500"
      >
        {children}
        <span className="absolute top-0 left-0 w-full h-[2px] bg-chartreuse animate-[animateTop_2s_linear_infinite] bg-green-500"></span>
        <span className="absolute top-0 right-0 h-full w-[2px] bg-chartreuse animate-[animateRight_2s_linear_-1s_infinite] bg-green-500"></span>
        <span className="absolute bottom-0 left-0 w-full h-[2px] bg-chartreuse animate-[animateBottom_2s_linear_infinite] bg-green-500"></span>
        <span className="absolute top-0 left-0 h-full w-[2px] bg-chartreuse animate-[animateLeft_2s_linear_-1s_infinite] bg-green-500"></span>
      </a>
    );
  }

  return (
    <button 
      className="relative inline-block align-middle appearance-none border-none outline-none bg-transparent rounded-none text-[#20d632] text-base font-semibold font-[Rubik,sans-serif] py-3.5 px-10 uppercase overflow-hidden text-center transition duration-500"
    >
      {children}
      <span className="absolute top-0 left-0 w-full h-[2px] bg-chartreuse animate-[animateTop_2s_linear_infinite] bg-slate-100"></span>
      <span className="absolute top-0 right-0 h-full w-[2px] bg-chartreuse animate-[animateRight_2s_linear_-1s_infinite] bg-slate-100"></span>
      <span className="absolute bottom-0 left-0 w-full h-[2px] bg-chartreuse animate-[animateBottom_2s_linear_infinite] bg-slate-100"></span>
      <span className="absolute top-0 left-0 h-full w-[2px] bg-chartreuse animate-[animateLeft_2s_linear_-1s_infinite] bg-slate-100"></span>
    </button>
  );
};

KiaanButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
};

export default KiaanButton;
