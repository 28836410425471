import React, { useState, useEffect } from 'react';
import { ChevronUp } from 'lucide-react'; // Ensure lucide-react is installed

const ScrollToTopButton = ({ scrollThreshold = 300 }) => {
  const [isVisible, setIsVisible] = useState(false);

  // Debounced scroll event to check scroll position
  const checkScrollTop = () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > scrollThreshold) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      checkScrollTop();
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup scroll event listener on unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollThreshold]); // Effect depends on scrollThreshold

  return (
    <button
      onClick={scrollToTop}
      aria-label="Scroll to top"
      className={`fixed bottom-4 right-4 p-3 bg-green-500 text-white rounded-full shadow-lg 
        transition-opacity duration-300 ease-in-out transform hover:scale-110 
        ${isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
    >
      <ChevronUp size={24} />
    </button>
  );
};

export default ScrollToTopButton;
