import React, { useState, useEffect, useRef } from 'react';
import KiaanButton from './KiaanButton';
import logo from "../Assets/Logo/Kiaan-Logo-1-1.png";
import { X, MapPin, Menu, Mail, Phone  } from 'lucide-react';
import { FaFacebook, FaWhatsapp, FaInstagram, FaLinkedin } from "react-icons/fa";
import { SiX } from 'react-icons/si'


const ContactInfo = ({ isOpen, onClose }) => {
  return (
    <div className={`fixed top-0 right-0 h-full w-96 bg-zinc-950 text-white p-8 transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'} z-50`}>
      <button onClick={onClose} className="absolute top-4 right-4 text-white hover:text-green-500">
        <X size={24} />
      </button>
      
      <img src={logo} alt="Kiaan Logo" className="w-24 mb-6" />
      
      <p className="text-lg  mb-4 font-serif">Picture a future in farming where smart solutions make every step easier and more efficient</p>
      
      <h2 className="text-2xl font-bold mb-4">Contact Info:</h2>
      
      <div className="space-y-4 mb-6">
        <div className="flex items-start">
          <MapPin className="mr-2 flex-shrink-0 text-green-500" size={20} />
          <p>18, 3rd Cross, BEML Layout, 5th Stage, (Behind Swarga Rani School), Rajarajeshwari Nagar, Bengaluru – 560098.</p>
        </div>
        <div className="flex items-center">
          <Mail className="mr-2 text-green-500" size={20} />
          <a href="mailto:connect@kiaanagrow.com" className="hover:underline">connect@kiaanagrow.com</a>
        </div>
        <div className="flex items-center">
          <Phone className="mr-2 text-green-500" size={20} />
          <a href="tel:+918618944125" className="hover:underline">+918618944125</a>
        </div>
      </div>
      
      <div className="flex space-x-4 mb-8">
      {/* <a href="https://www.facebook.com" aria-label="Facebook" target="_blank" rel="noopener noreferrer" className="text-white hover:text-green-500 transition duration-300">
                <FaFacebook size={24} />
              </a> */}
              <a href="https://wa.me/+918618944125?text=Hello%20there" aria-label="WhatsApp" target="_blank" rel="noopener noreferrer" className="text-white hover:text-green-500 transition duration-300">
                <FaWhatsapp size={24} />
              </a>
              <a href="https://x.com/kiaanagrow?t=hQA1htSHHpGGHtHcTR3KtA&s=08" aria-label="X (formerly Twitter)" target="_blank" rel="noopener noreferrer" className="text-white hover:text-green-500 transition duration-300">
                <SiX size={24} />
              </a>
              <a href="https://www.instagram.com/kiaan.agrowtech?igsh=cDJsMmZqOThleWxx" aria-label="Instagram" target="_blank" rel="noopener noreferrer" className="text-white hover:text-green-500 transition duration-300">
                <FaInstagram size={24} />
              </a>
              <a href="https://www.linkedin.com/company/kiaanagrowtech/posts/?feedView=all" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn" className="text-white hover:text-green-500 transition duration-300">
                <FaLinkedin size={24} />
              </a>
        
        
      </div>
    </div>
  );
};

export default function Navbar() {
    const [isContactOpen, setIsContactOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const contactRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 10);
        };

        const handleClickOutside = (event) => {
            if (contactRef.current && !contactRef.current.contains(event.target)) {
                setIsContactOpen(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const navItems = [
        { label: 'Home', href: 'home' },
        { label: 'Projects', href: 'projects' },
        { label: 'Solutions', href: 'solutions' },
        { label: 'Products', href: 'ecom' },
        { label: 'Contact', href: 'contact' }
    ];

    return (
        <>
            <nav className={`fixed top-0 left-0 right-0 z-40 transition-all duration-300 ${isScrolled ? 'bg-zinc-950 bg-opacity-40' : 'bg-zinc-950 bg-opacity-40'}`}>
                <div className="max-w-screen-2xl flex items-center justify-between mx-auto px-6 h-16">
                    <a href="/" className="flex items-center space-x-3">
                        <img src={logo} className="h-8" alt="Kiaan Logo" />
                    </a>
                    
                    <div className="hidden md:flex items-center space-x-4 lg:space-x-4 ml-auto">
                        <ul className="font-medium flex flex-row space-x-5 lg:space-x-6">
                            {navItems.map((item) => (
                                <li key={item.label} className="relative group">
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            scrollToSection(item.href);
                                            setIsMobileMenuOpen(false);
                                            setIsContactOpen(false);
                                        }}
                                        className="block py-2 text-white hover:text-green-400 transition-colors duration-300 ease-in-out text-sm lg:text-base"
                                    >
                                        {item.label}
                                        <span className="absolute bottom-0 left-0 w-0 h-px bg-current transition-all duration-300 ease-in-out group-hover:w-full"></span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                        <div className="hidden lg:block">
                            <KiaanButton href="#farmsoftware">
                                <span className="relative z-10 font-semibold text-green-400 text-sm lg:text-base">Discover More</span>
                            </KiaanButton>
                        </div>
                        <button 
                            onClick={() => setIsContactOpen(true)}
                            className="relative w-[60px] lg:w-[80px] h-[40px] lg:h-[60px] overflow-hidden border-0 outline-0 bg-[#44a05b] p-0 flex items-center justify-center group hover:bg-[#3a8a4d] transition-colors"
                        >
                            {/* Ripple Effect */}
                            <span className="absolute w-0 h-0 rounded-full bg-white opacity-75 group-hover:animate-ripple"></span>
                            
                            {/* Icon */}
                            <div className="relative z-10 flex flex-col items-center justify-center transition-transform duration-500 ease-in-out group-hover:scale-125">
                                {/* Top Bar */}
                                <span className="block w-[14px] lg:w-[18px] h-[2px] bg-white mb-[2px] transition-all duration-300 ease-in-out group-hover:rotate-45"></span>
                                {/* Middle Bar */}
                                <span className="block w-[14px] lg:w-[18px] h-[2px] bg-white transition-all duration-300 ease-in-out group-hover:opacity-0"></span>
                                {/* Bottom Bar */}
                                <span className="block w-[14px] lg:w-[18px] h-[2px] bg-white mt-[2px] transition-all duration-300 ease-in-out group-hover:-rotate-45"></span>
                            </div>
                        </button>
                    </div>

                    <button
                      className="md:hidden text-white"
                      onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                    >
                      {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                </div>

                {/* Mobile menu */}
                <div className={`md:hidden fixed top-0 left-0 bottom-0 w-64 bg-zinc-900 transform transition-transform duration-300 ease-in-out ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}`}>
                    <div className="p-4">
                        <img src={logo} alt="Kiaan Logo" className="w-24 mb-6" />
                        <ul className="flex flex-col">
                            {navItems.map((item) => (
                                <li key={item.label} className="mb-4">
                                    <a
                                        onClick={(e) => {
                                            e.preventDefault();
                                            scrollToSection(item.href);
                                            setIsMobileMenuOpen(false);
                                            setIsContactOpen(false);
                                        }}
                                        className="block text-white hover:text-[#44a05b] transition-colors duration-300 ease-in-out"
                                    >
                                        {item.label}
                                    </a>
                                </li>
                            ))}
                        </ul>
                        <div className="flex space-x-4 mb-8">
                        {/* <a href="https://www.facebook.com" aria-label="Facebook" target="_blank" rel="noopener noreferrer" className="text-white hover:text-green-500 transition duration-300">
                <FaFacebook size={24} />
              </a> */}
              <a href="https://wa.me/+918618944125?text=Hello%20there" aria-label="WhatsApp" target="_blank" rel="noopener noreferrer" className="text-white hover:text-green-500 transition duration-300">
                <FaWhatsapp size={24} />
              </a>
              <a href="https://x.com/kiaanagrow?t=hQA1htSHHpGGHtHcTR3KtA&s=08" aria-label="X (formerly Twitter)" target="_blank" rel="noopener noreferrer" className="text-white hover:text-green-500 transition duration-300">
                <SiX size={24} />
              </a>
              <a href="https://www.instagram.com/kiaan.agrowtech?igsh=cDJsMmZqOThleWxx" aria-label="Instagram" target="_blank" rel="noopener noreferrer" className="text-white hover:text-green-500 transition duration-300">
                <FaInstagram size={24} />
              </a>
              <a href="https://www.linkedin.com/company/kiaanagrowtech/posts/?feedView=all" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn" className="text-white hover:text-green-500 transition duration-300">
                <FaLinkedin size={24} />
              </a>
                        </div>
                    </div>
                </div>
            </nav>

            <ContactInfo isOpen={isContactOpen} onClose={() => setIsContactOpen(false)} />
        </>
    );
}